
import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import { API_BASE_URL } from '../config';

/**
 * Sanitize markdown links to prevent invalid URL formatting.
 */
function sanitizeMarkdownLinks(text) {
  text = text.replace(/\[([^\]]+)\]/g, (match, label) => `[${label.replace(/\s+/g, ' ').trim()}]`);
  text = text.replace(/\]\(\s*([^()]+)\s*\)/g, (match, urlPart) => {
    const trimmed = urlPart.trim();
    const lower = trimmed.toLowerCase();
    if (lower.startsWith('http') || lower.startsWith('mailto')) {
      const cleaned = trimmed.replace(/\s*:\s*/g, ':')
        .replace(/\s*\/\s*/g, '/')
        .replace(/\s*\.\s*/g, '.')
        .replace(/\s*\?\s*/g, '?')
        .replace(/\s*=\s*/g, '=')
        .replace(/\s*#\s*/g, '#')
        .replace(/-\s*/g, '-') // Remove the unnecessary backslash here
        .replace(/\s+/g, '').trim();
      return `](${cleaned})`;
    }
    return `](${trimmed})`;
  });
  return text;
}


/**
 * Removes extra spaces between ** and the content.
 */
function fixDoubleStarSpacing(text) {
  return text.replace(/\*\*\s+(.*?)\s+\*\*/g, '**$1**');
}

// Render tables for balance statements
function renderTable(data) {
  if (!data || data.length === 0) return <p>No data available</p>;

  return (
    <div style={{ overflowX: 'auto', marginTop: '16px' }}>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          textAlign: 'left',
          border: '2px solid black',
        }}
      >
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2', borderBottom: '2px solid black' }}>
            <th style={{ padding: '10px', border: '1px solid black' }}>Date & Time</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>Amount</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>Old Balance</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>New Balance</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>Remark</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td style={{ padding: '10px', border: '1px solid black' }}>{row.transaction_date}</td>
              <td style={{ padding: '10px', border: '1px solid black' }}>
                {row.amount_added ? `+₹${row.amount_added}` : `-₹${row.amount_deducted || 0}`}
              </td>
              <td style={{ padding: '10px', border: '1px solid black' }}>₹{row.old_balance}</td>
              <td style={{ padding: '10px', border: '1px solid black' }}>₹{row.new_balance}</td>
              <td style={{ padding: '10px', border: '1px solid black' }}>{row.remark}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Render tables for plan details
function renderPlanTable(data) {
  if (!data || data.length === 0) return <p>No data available</p>;

  return (
    <div style={{ overflowX: 'auto', marginTop: '16px' }}>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          textAlign: 'left',
          border: '2px solid black',
        }}
      >
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2', borderBottom: '2px solid black' }}>
            <th style={{ padding: '10px', border: '1px solid black' }}>Payment Mode</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>Plan Start Date</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>Plan End Date</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>Router NAS ID</th>
            <th style={{ padding: '10px', border: '1px solid black' }}>Total Paid</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td style={{ padding: '10px', border: '1px solid black' }}>{row.payment_mode}</td>
              <td style={{ padding: '10px', border: '1px solid black' }}>{row.plan_start_date}</td>
              <td style={{ padding: '10px', border: '1px solid black' }}>{row.plan_end_date}</td>
              <td style={{ padding: '10px', border: '1px solid black' }}>{row.router_nas_id}</td>
              <td style={{ padding: '10px', border: '1px solid black' }}>₹{row.total_paid}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Unified Chat Component
function UnifiedChat() {
  const { id } = useParams(); // Get chatbot ID from route
  const [chatHistory, setChatHistory] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [chatbotDetails, setChatbotDetails] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [planTableData, setPlanTableData] = useState(null);

  useEffect(() => {
    const fetchChatbotDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/chatbot/${id}`);
        const data = await response.json();

        if (response.ok) {
          setChatbotDetails(data);
          setChatHistory([]); // Clear chat history for new chatbot
        } else {
          console.error('Failed to fetch chatbot details');
        }
      } catch (error) {
        console.error('Error fetching chatbot details:', error);
      }
    };

    if (id) fetchChatbotDetails();
  }, [id]);

  const sendMessage = useCallback(async () => {
    if (!userMessage.trim() || !id) return;

    setChatHistory((prev) => [...prev, { role: 'user', content: userMessage }]);
    setUserMessage('');
    setIsTyping(true);

    try {
      const response = await fetch(`${API_BASE_URL}/chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chatbot_id: id,
          message: userMessage,
          chatHistory,
        }),
      });

      const data = await response.json();

      if (response.ok && data.response) {
        if (Array.isArray(data.response.data)) {
          setTableData(data.response.data);
        } else {
          let sanitizedResponse = sanitizeMarkdownLinks(data.response);
          sanitizedResponse = fixDoubleStarSpacing(sanitizedResponse);
          setChatHistory((prev) => [
            ...prev,
            { role: 'assistant', content: sanitizedResponse },
          ]);
        }
      } else if (response.ok && data.responseType) {
        if (data.responseType === 'balanceSheet') {
          setTableData(data.response.data);
        } else if (data.responseType === 'planDetails') {
          setPlanTableData(data.response.data);
        } else {
          console.error('Unhandled responseType:', data.responseType);
        }
      } else {
        console.error('Error from backend:', data.error);
        setChatHistory((prev) => [
          ...prev,
          { role: 'assistant', content: 'Please try again later.' },
        ]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      
      setChatHistory((prev) => [
        ...prev,
        { role: 'assistant', content: 'Please try again later.' },
      ]);
    } finally {
      setIsTyping(false);
    }
  }, [userMessage, chatHistory, id]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="flex-1 flex flex-col justify-between p-8 w-full max-w-5xl mx-auto">
      {chatbotDetails.chatbot_name && (
        <h2 className="text-2xl font-bold text-center mb-4 text-pink-600">
          Chat with {chatbotDetails.chatbot_name}
        </h2>
      )}
      <div className="bg-white bg-opacity-25 text-black p-4 rounded-lg shadow-lg border-2 border-[#A5E5C6] hover:border-pink-600 mb-4 w-full h-[600px] overflow-y-auto">
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            className={`flex ${chat.role === 'user' ? 'justify-start' : 'justify-end'}`}
          >
            <div
              className={`max-w-[30rem] p-3 rounded-2xl shadow-lg ${
                chat.role === 'user' ? 'bg-white text-pink-600' : 'bg-pink-600 text-white'
              }`}
            >
              <span className="font-bold">
                {chat.role === 'user'
                  ? chatbotDetails.user_name || 'You'
                  : chatbotDetails.chatbot_name || 'Assistant'}
                :
              </span>{' '}
<ReactMarkdown
  children={chat.content}
  remarkPlugins={[remarkGfm]}
  rehypePlugins={[rehypeSanitize]}
  components={{
    a: ({ node, href, children, ...props }) => {
      // Get all siblings of the current <a> tag
      const siblings = node?.parent?.children || [];

      // Extract text before the link
      let description = siblings
        .slice(0, siblings.indexOf(node))
        .map(child => (child.type === "text" ? child.value.trim() : ""))
        .join(" ")
        .replace(/\(Click Here\)/gi, "") // Remove extra "(Click Here)"
        .trim();

      // Check if it's an email link (mailto:)
      if (href.startsWith("mailto:")) {
        return <span>{href.replace("mailto:", "")}</span>; // Show raw email as plain text
      }

      return (
        <>
          {description} {/* Keeps the descriptive text */}
          {" "}
          (<a 
            href={href} 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: 'blue', textDecoration: 'underline', fontWeight: 'bold' }}
          >
            Click here
          </a>)
        </>
      );
    }
  }}
/>
            </div>
          </div>
        ))}
        {isTyping && <p className="text-gray-500 italic">{chatbotDetails.chatbot_name || 'Assistant'} is typing...</p>}
      </div>

      {tableData && (
        <div>
          <h3 className="text-lg font-semibold text-center mt-4 mb-2">Balance Statement</h3>
          {renderTable(tableData)}
        </div>
      )}

      {planTableData && (
        <div>
          <h3 className="text-lg font-semibold text-center mt-4 mb-2">Plan Details</h3>
          {renderPlanTable(planTableData)}
        </div>
      )}

      <div className="flex items-center w-full space-x-4">
        <input
          type="text"
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={`Message ${chatbotDetails.chatbot_name || 'Chatbot'}`}
          className="bg-pink-50 text-black border-2 p-3 rounded-lg w-full pr-12 border-[#A5E5C6] focus:outline-none focus:border-pink-800 transition duration-200 shadow-lg hover:border-pink-600"
        />
        <button onClick={sendMessage} className="text-[#D9008D] hover:text-pink-800">
          <FaPaperPlane size={24} />
        </button>
      </div>
    </div>
  );
}

export default UnifiedChat;

